import React from 'react'
import '../../css/Hosting/Hosting.css';
import ImageDetails from '../ImageDetails'
import DetailsImage from '../DetailsImage';
import { Link } from 'react-router-dom';

const Hosting = () => {

    const heading = "Secure Web Deployment"
    const detailsFirstHalf = "We prioritize the security of your online presence by ensuring that your websites are deployed with best practices in mind. Our hosting solutions include secure deployment procedures that protect your site from vulnerabilities, ensuring that your data remains safe and your website remains accessible. We focus on maintaining a secure environment, allowing you to confidently grow your business online."

    const detailsFirstHalf2 = "At Devsaura, we understand that maintaining a website is just as crucial as building one. Our comprehensive web management and maintenance services ensure that your site remains up-to-date, secure, and fully functional. From regular software updates and security patches to fixing bugs and optimizing performance, we take care of all the technical aspects so you can focus on your business. "
    const detailsSecondHalf2 = "Our proactive approach includes continuous monitoring to detect and resolve issues before they impact your users, as well as making sure your website evolves with your business needs. Whether it’s content updates, feature enhancements, or technical support, Devsaura is dedicated to keeping your online presence running smoothly and efficiently."
    return (
        <div className="web-hosting">
            <div className='Hosting'>
                <div className="home-content center">
                    <h1>Reliable Hosting Solutions</h1>
                    <p>Ensure your website's performance and accessibility
                        with our expert hosting solutions. We focus on seamless
                        setup, secure management, and reliable maintenance,
                        allowing you to focus on growing your business. From
                        static site hosting to custom domain setup, our services
                        are designed to keep your digital presence strong and
                        hassle-free.</p>
                </div>
            </div>
            <ImageDetails
                detailsFirstHalf={detailsFirstHalf}
                imgPath={"../images/encryption.png"}
                heading={heading}
            />
            <DetailsImage
                detailsFirstHalf={detailsFirstHalf2}
                detailsSecondHalf={detailsSecondHalf2}
                imgPath={"../images/bug.png"}
                heading={"Web Management & Maintenance"}
            />
            <div className="center">
                <Link to={'/contact-us'} className='shine button '>Get Support</Link>
            </div>
        </div>
    )
}

export default Hosting
