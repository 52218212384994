import React from 'react'
import '../css/Image_Details.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';


const Image_Details = ({ detailsFirstHalf, detailsSecondHalf, detailsThirdHalf = "", imgPath, heading }) => {
  useIntersectionObserver('flex');
  useIntersectionObserver('image-and-details');

  return (
    <div className='Image_Details'>
      {imgPath && (
        <div className='flex hide-on-phone'>
          <img src={imgPath} alt="left-pic" />
        </div>
      )}
      <div className='image-and-details'>
        <h1>{heading}</h1>
        <div className="seperator-line"></div>
        <p>{detailsFirstHalf} <br /><br /> {detailsSecondHalf} <br /> <br />{detailsThirdHalf}</p>
      </div>
    </div>
  )
}

export default Image_Details
