import React from 'react'
import '../css/About.css';
import Goals from './Goals'
import ImageDetails from './ImageDetails';

const About = () => {
    const heading = "Know More About Us";

    const detailsFirstHalf = `At DevsAura, we are dedicated to driving digital innovation 
                    by providing software solutions that meet the evolving needs 
                    of businesses. Our team of skilled professionals blends 
                    creativity and precision to address your unique challenges, 
                    crafting strategies designed to deliver meaningful results.`;

    const detailsSecondHalf = `We are committed to providing more than just software—we 
                    deliver powerful tools that boost online visibility, 
                    streamline operations, and foster growth. Whether you're 
                    seeking tailored software solutions or expert insights, 
                    Devsaura is your trusted partner in navigating the 
                    digital landscape. We consistently deliver high-quality 
                    work, on time, ensuring your business excels in the 
                    competitive digital world.`;

    const detailsThirdHalf = `Join us at Devsaura and step into the future of 
                    technology today.`;

    const imgPath = '../images/spaceship.png';

    return (
        <>
            <div className='About'>
                <div className="home-content center">
                    <h1>Where Tech Visionaries Unite</h1>
                    <p>At Devsaura, we blend cutting-edge software solutions
                        with visionary thinking to elevate your business.
                        Collaborate with us to transform ideas into impactful
                        digital realities.</p>
                </div>
            </div>
            <ImageDetails
                detailsFirstHalf={detailsFirstHalf}
                detailsSecondHalf={detailsSecondHalf}
                detailsThirdHalf={detailsThirdHalf}
                imgPath={imgPath}
                heading={heading}
            />
            <Goals />
        </>
    )
}

export default About
