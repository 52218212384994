import React from 'react';
import '../../css/Process/ProcessStep.css';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const ProcessStep = ({ title, steps }) => {
    useIntersectionObserver('fade-in');

    return (
        <div className='ProcessStep fade-in'>
            <div className="process-content">
                <h1>{title}</h1>
                <div className="seperator-line"></div>
                <div className="steps">
                    {steps.map((step, index) => (
                        <div key={index}>
                            <h3>{step.heading}</h3>
                            <p>{step.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProcessStep;
