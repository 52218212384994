import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const sendPageView = (url) => {
      const sessionId = getCookie('user_session');

      window.gtag('config', 'G-1ZV58PK9JG', {
        page_path: url,
        user_session: sessionId || 'unknown',
      });
    };

    const trackSession = () => {
      let sessionId = getCookie('user_session');

      if (!sessionId) {
        sessionId = `session_${Math.random().toString(36).substring(2)}`;
        setCookie('user_session', sessionId, 7);
        console.log('New session created:', sessionId);
      } else {
        console.log('Existing session found:', sessionId);
      }

      return sessionId;
    };

    trackSession(); 
    sendPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

export default GoogleAnalytics;
