import React, { useEffect } from 'react';
import '../css/HomeInsights.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const HomeInsights = () => {
    useIntersectionObserver('count-up');

    useEffect(() => {
        const elements = document.querySelectorAll('.count-up');

        const startCounting = (element) => {
            const finalValue = parseInt(element.getAttribute('data-final-value'), 10);
            let currentValue = 0;
            const interval = setInterval(() => {
                currentValue += Math.ceil(finalValue / 100);
                if (currentValue >= finalValue) {
                    currentValue = finalValue;
                    clearInterval(interval);
                }
                element.querySelector('.count-number').textContent = currentValue;
            }, 10);
        };

        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
                    entry.target.classList.add('animated');
                    startCounting(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, { threshold: 0.1 });

        elements.forEach((element) => {
            observer.observe(element);
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className='HomeInsights fade-in'>
            <div className="insight center">
                <img src="../images/insights/commitment.png" alt="Client Commitment" />
                <b className='count-up' data-final-value="100"><span className='count-number'>0</span><span>%</span></b>
                <p>Client Commitment</p>
            </div>
            <div className="insight center">
                <img src="../images/insights/satisfied.png" alt="Client Satisfaction" />
                <b className='count-up' data-final-value="100"><span className='count-number'>0</span><span>%</span></b>
                <p>Client Satisfaction</p>
            </div>
            <div className="insight center">
                <img src="../images/insights/success.png" alt="Successful Projects" />
                <b className='count-up' data-final-value="5"><span className='count-number'>0</span><span>+</span></b>
                <p>Successful Projects</p>
            </div>
            <div className="insight center">
                <img src="../images/insights/calendar.png" alt="On Time Delivery" />
                <b className='count-up' data-final-value="100"><span className='count-number'>0</span><span>%</span></b>
                <p>On Time Delivery</p>
            </div>
        </div>
    )
}

export default HomeInsights;
