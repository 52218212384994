import React from 'react';
import '../css/Join.css';
import JoinForm from './JoinForm';
import DetailsImage from './DetailsImage';
import Heading from './Heading';
import FourBenifits from './FourBenifits';


const Join = () => {


    const detailsFirstHalf = "We're on a mission to create innovative software solutions, and we’re looking for like-minded individuals to join us on this journey. If you’re passionate about technology, eager to take on meaningful projects, and ready to contribute your skills to something bigger, we’d love to have you on board."
    const detailsSecondHalf = "This isn’t just about filling a role—it’s about collaborating with a small but driven team where every idea counts. Whether you’re a developer, designer, or tech enthusiast, you’ll find an opportunity to make an impact and grow alongside us."
    const detailsThirdHalf = "Let’s come together to shape the future of technology. If you’re ready to be part of a team that values creativity, flexibility, and shared success, we invite you to join us."

    const benifitHeadings = [
        "Flexible Working Hours",
        "Remote Work Opportunities",
        "Innovative Projects",
        "Growth and Development"
    ];

    const descriptions = [
        "Tailor your work schedule to fit your life, ensuring a healthy balance.",
        "Work from wherever you feel most productive, with full support from our team.",
        "Collaborate on cutting-edge projects that shape the future of technology.",
        "Continuous learning opportunities to help you expand your skills and advance your career."
    ];

    const imgPaths = [
        "../images/team-benifits/hourglass.png",
        "../images/team-benifits/remote-work.png",
        "../images/team-benifits/idea.png",
        "../images/team-benifits/success.png",
    ]


    return (
        <>
            <div className='Join'>
                <div className="home-content center">
                    <h1>Shape the Future with Us</h1>
                    <p>Become part of a dynamic and innovative team
                        where your skills and creativity are valued.
                        As we believe in empowering our
                        people to push boundaries and achieve
                        excellence. Join us in shaping the future of
                        technology, and make an impact through
                        collaboration, continuous learning, and a
                        shared passion for innovation.
                    </p>
                </div>
            </div>
            <DetailsImage
                detailsFirstHalf={detailsFirstHalf}
                detailsSecondHalf={detailsSecondHalf}
                detailsThirdHalf={detailsThirdHalf}
                imgPath={"../images/teamwork.png"}
                heading={"Join Us in Building Something Great"} />
            <Heading
                title={"What You Get?"}
                description={"Joining Devsaura means gaining access to a range of benefits designed to support your growth and creativity. Here’s what you can expect when you become part of our team."}
            />
            <FourBenifits
                className="first"
                benifitHeadings={benifitHeadings}
                benifitDescriptions={descriptions}
                benifitImgPaths={imgPaths}
            />
            <Heading
                title={"Ready to Make an Impact?"}
                description={" If you're passionate about pushing boundaries and creating solutions that make a difference, we want to hear from you. Fill out the form below to take the first step in joining our journey toward excellence."}
            />
            <JoinForm id='join-form' />
        </>
    );
};

export default Join;
