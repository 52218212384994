import React, { useEffect } from 'react';
import './css/App.css';
import '../src/config/ui/pi/config'
import { setCookie, getCookie } from './utils/cookies';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Components/Home';
import About from './Components/About';
import Services from './Components/Services';
import Join from './Components/Join';
import Partner from './Components/Partner';
import Contact from './Components/Contact';
import ScrollToTop from './Components/ScrollToTop';
import WebDevelopment from './Components/Web/WebDevelopment';
import Hosting from './Components/Hosting/Hosting';
import GoogleAnalytics from '../src/config/ui/pi/GoogleAnalytics';
import Process from './Components/Process/Process';
import ECommerce from './Components/ECommerce';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
function App() {
  useEffect(() => {
    const userSession = getCookie('user_session');

    if (!userSession) {
      const newSessionId = `session_${Math.random().toString(36).substring(2)}`;
      setCookie('user_session', newSessionId, 15);
    }
  }, []);

  return (
    <>
      <Router>
        <GoogleAnalytics />
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/services' element={<Services />}></Route>
          <Route path='/join-us' element={<Join />}></Route>
          <Route path='/partner-with-us' element={<Partner />}></Route>
          <Route path='/contact-us' element={<Contact />}></Route>
          <Route path='/services/web-development' element={<WebDevelopment />}></Route>
          <Route path='/services/hosting-solutions' element={<Hosting />}></Route>
          <Route path='/services/web-development/our-process' element={<Process />}></Route>
          <Route path='/services/e-commerce' element={<ECommerce />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
