import React from 'react';
import '../css/ECommerce.css';
import ImageDetails from './ImageDetails';
import Heading from './Heading';
import FourBenifits from './FourBenifits';
import { Link } from 'react-router-dom';
import Technologies from './Technologies';

const ECommerce = () => {
    const heading = "E-Commerce Solutions"
    const detailsFirstHalf = "In the fast-paced world of online retail, having a robust e-commerce platform is crucial to staying competitive. At Devsaura, we specialize in creating e-commerce solutions that are not only visually appealing but also optimized for performance. Our platforms are designed to provide seamless shopping experiences that turn visitors into loyal customers, ensuring your online store is a powerful revenue-generating tool."
    const detailsSecondHalf = "We know that maintaining a successful online store requires ongoing attention. That's why we offer continuous support and updates post-launch to keep your e-commerce site running smoothly and securely. From initial setup to long-term growth, our team is dedicated to helping you scale your online business and achieve sustained success in the digital marketplace."

    const benifitHeadings = [
        "Seamless User Experience",
        "Scalability & Growth",
        "Cost-Effective Implementation",
        "Secure Transactions"
    ];
    const benifitHeadings2 = [
        "Customizable Solutions",
        "Continuous Support",
        "Optimized Performance",
        "Revenue Growth Enablement"
    ];

    return (
        <><div className='web-development'>
            <div className='WebDevelopment'>
                <div className="home-content center">
                    <h1>E-Commerce Development</h1>
                    <p>Empower your business with custom e-commerce solutions designed to drive growth and customer loyalty. Whether launching a new online store or upgrading an existing one, we craft scalable, user-friendly platforms that align with your business goals and enhance your digital presence.</p>
                </div>
            </div>
            <ImageDetails
                detailsFirstHalf={detailsFirstHalf}
                detailsSecondHalf={detailsSecondHalf}
                imgPath={"../images/shopping.png"}
                heading={heading} />
            <Heading
                title={"Advantages Of Our E-Commerce Service"}
                description={"Our tailored e-commerce solutions focus on creating intuitive, secure, and scalable platforms that support your business growth and deliver an exceptional online shopping experience."} />
            <FourBenifits
                className="first"
                benifitHeadings={benifitHeadings} />
            <FourBenifits
                className="second"
                benifitHeadings={benifitHeadings2} />
            <div className="heading-cover">
                <Heading
                    title={"Ready to Launch or Upgrade Your Online Store?"}
                    description={"Transform your e-commerce vision into reality with our expert development services. Whether you're starting fresh or optimizing an existing store, we create tailored solutions that drive sales and foster customer loyalty. Let’s work together to build a thriving online business."} />
                <div className="center">
                    <Link to={'/contact-us'} className='shine button '>Let's Build It</Link>
                </div>
            </div>
        </div>
            <Technologies />
        </>
    )
}

export default ECommerce;
