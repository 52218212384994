import React from 'react';
import '../css/FourBenifits.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const FourBenifits = ({
    benifitImgPaths = [],
    benifitHeadings = [],
    benifitDescriptions = [],
    className = ''
}) => {
    useIntersectionObserver('fade-in');

    const benefits = Array.from({ length: 4 }, (_, i) => ({
        imgPath: benifitImgPaths[i] || '',
        heading: benifitHeadings[i] || '',
        description: benifitDescriptions[i] || ''
    }));

    return (
        <div className={`FourBenifits ${className}`}>
            <div className='benifits'>
                {benefits.map((benefit, index) => (
                    <div className="benifit fade-in" key={index}>
                        {benefit.imgPath && (
                            <img src={benefit.imgPath} alt={`Benefit ${index + 1}`} />
                        )}
                        <h3>{benefit.heading}</h3>
                        <p>{benefit.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FourBenifits;
