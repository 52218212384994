import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import '../css/Header.css';

const Header = () => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    const height = isHomePage ? '100vh' : '90vh';

    const [activeService, setActiveService] = useState('development');
    const [isHamActive, setIsHamActive] = useState(false);
    const [isServicesMenuActive, setIsServicesMenuActive] = useState(false);

    const toggleHamMenu = () => {
        setIsHamActive(!isHamActive);
    };

    const toggleServicesMenu = () => {
        setIsServicesMenuActive(!isServicesMenuActive);
    };

    return (
        <>
            <div className="logo for-phone">
                <Link to="/">
                    <img id='favicon-for-phone' src="/images/fav-da.png" alt="Logo" />
                </Link>
            </div>
            <video
                autoPlay
                muted
                loop
                playsInline
                id="bg-video"
                style={{ height }}
            >
                <source src="/images/bg-vid.mp4" type="video/mp4" />
                Your browser does not support HTML5 video.
            </video>
            <div
                className="overlay"
                style={{ height }}
            ></div>
            <div
                className="overlay2"
                style={{ height }}
            ></div>
            <div
                className={`overlay-ham ${isHamActive ? 'active' : ''}`}
                onClick={toggleHamMenu}
            ></div>
            <header className={isHamActive ? 'active' : ''}>
                <div
                    className={`ham ${isHamActive ? 'active' : ''}`}
                    onClick={toggleHamMenu}
                >
                    <div className="ham-line"></div>
                    <div className="ham-line"></div>
                    <div className="ham-line"></div>
                </div>
                <div className="logo">
                    <Link to="/">
                        <img id='favicon' src="/images/fav-da.png" alt="Logo" />
                        <img src="/images/devsaura2.png" alt="Logo" />
                    </Link>
                </div>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About Us</Link></li>
                    <li id='service'>
                        <Link to="/services" onClick={toggleServicesMenu}>Services<span> &gt; </span></Link>
                        <div className={`services-preview ${isServicesMenuActive ? 'active' : ''}`}>
                            <div className="services-menu">
                                <Link
                                    id='development'
                                    to="/services/web-development"
                                    onMouseEnter={() => setActiveService('development')}
                                >
                                    Web Development
                                </Link>
                                <Link
                                    id='e-com'
                                    to="/services/e-commerce"
                                    onMouseEnter={() => setActiveService('e-com')}
                                >
                                    E-commerce Solutions
                                </Link>
                                <Link
                                    id='hosting'
                                    to="/services/hosting-solutions"
                                    onMouseEnter={() => setActiveService('hosting')}
                                >
                                    Hosting Solutions
                                </Link>
                            </div>
                            <div className="services hide-on-phone">
                                <div className={`inside-services development ${activeService === 'development' ? 'visible' : ''}`}>
                                    <Link to="/services/web-development"><img src="/images/services/coding.png" alt="Web-Development" />Web Development</Link>
                                    <Link to="/services/web-development/our-process"><img src="/images/services/iteration.png" alt="our-process" />Our Process</Link>
                                </div>
                                <div className={`inside-services hosting ${activeService === 'e-com' ? 'visible' : ''}`}>
                                    <Link to="/services/e-commerce"><img src="/images/services/shopify.png" alt="Shopify" />Shopify</Link>
                                    <Link to="/services/e-commerce"><img src="/images/services/wordpress.png" alt="Wordpress" />Wordpress</Link>
                                </div>
                                <div className={`inside-services hosting ${activeService === 'hosting' ? 'visible' : ''}`}>
                                    <Link to="/services/hosting-solutions"><img src="/images/services/domain.png" alt="Domain Name Setup" />Domain Setup</Link>
                                    <Link to="/services/hosting-solutions"><img src="/images/services/software.png" alt="Site Management" />Site Management</Link>
                                </div>
                            </div>
                        </div>

                    </li>
                    <li><Link to="/join-us">Join Our Team</Link></li>
                    <li><Link to="/partner-with-us">Partner with us</Link></li>
                    <li id='contact'>
                        <Link to="/contact-us">Contact<span> &gt; </span></Link>
                        <div className='contact-preview'>
                            <div className="contact-menu">
                                <div className="contacts">
                                    <div className="title">
                                        <b>General Inquiries:</b>
                                        <b>Project Related:</b>
                                        <b>Call or Whatsapp:</b>
                                    </div>
                                    <div className="numbers">
                                        <a href="mailto:info@devsaura.com">info@devsaura.com</a>
                                        <a href="mailto:project@devsaura.com">project@devsaura.com</a>
                                        <a href="https://wa.me/message/UF65W56P44SIG1" target='_blank' rel='noreferrer noopener'>+92 (314) 6036065</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </li>
                </ul>
                <div className="quote-btn center">
                    <button className="shineOnHover" aria-label="Get a Quote">Get a Quote</button>
                </div>
            </header>
        </>
    );
}

export default Header;
