import React from 'react'
import '../../css/footer/Bottom.css'

const Bottom = () => {
    return (
        <div className='Bottom center'>
            <p>&copy; 2024 Devsaura. All rights reserved.</p>
            <p>
                <a href="#privacy-policy">Privacy Policy</a> |  <a href="#terms-of-service">Terms of Service</a>
            </p>
        </div>
    )
}

export default Bottom
