import React from 'react'
import '../css/Partner.css';
import { Link } from 'react-router-dom';

import Heading from './Heading';
import FourBenifits from './FourBenifits';
import ImageDetails from './ImageDetails';

const Partner = () => {
    const heading = "Profitability Through Partnership";
    const detailsFirstHalf = `We believe that a strong partnership is the
                    cornerstone of mutual success. Our approach to partnerships
                    is built on trust, collaboration, and shared goals. We
                    understand that every partnership is unique, and we
                    prioritize open communication and flexibility to adapt to
                    your specific needs.`;
    const detailsSecondHalf = `We see our partners as an integral part of our journey,
                    involving them in key decisions and fostering an environment
                    where ideas and innovation thrive. Together, we aim to
                    create solutions that not only meet today’s challenges but
                    also set the stage for future growth. Our commitment to
                    excellence ensures that every partnership is driven by
                    measurable results, with a focus on delivering outstanding
                    outcomes.`;
    const detailsThirdHalf = ` By partnering with Devsaura, you’re not just collaborating
                    with a software company—you’re joining a team that values
                    your success as much as our own. Let’s achieve greatness
                    together and celebrate our shared victories.`

    const imgPath = "../images/agreement.png";

    const title = "What We Offer?";
    const description = `Our collaboration is rooted in trust and shared goals,
                    ensuring that our partners experience sustainable growth
                    and success through a transparent and results-driven
                    approach.`


    const benifitImgPaths = [
        "../images/partner/budget.png",
        "../images/partner/digital-services.png",
        "../images/partner/update.png",
        "../images/partner/technical-support.png"
    ];

    const benifitHeadings = [
        "50% Cost Efficiency",
        "Expand Service Offerings",
        "Transparent Progress Updates",
        "Uncompromised Quality and Support"
    ];

    const benifitDescriptions = [
        "Reduce project expenses by leveraging our expertise without the need to hire full in-house teams.",
        "We enable you to offer cutting-edge software solutions, enhancing your service portfolio and market reach.",
        "Stay informed with regular updates from our project manager, keeping you in the loop at every stage.",
        "We prioritize delivering top-quality solutions, backed by ongoing support to ensure long-term success."
    ];


    return (
        <><div className='Partner'>
            <div className="home-content center">
                <h1>Experience Innovation Through Partnership</h1>
                <p>Partner with DevsAura to unlock transformative solutions
                    and drive your business forward. Together, we turn visionary
                    concepts into innovative realities that set you apart in the
                    digital landscape.</p>
            </div>
        </div>
            <ImageDetails
                detailsFirstHalf={detailsFirstHalf}
                detailsSecondHalf={detailsSecondHalf}
                detailsThirdHalf={detailsThirdHalf}
                imgPath={imgPath}
                heading={heading}
            />
            <Heading
                title={title}
                description={description}
            />
            <FourBenifits
                benifitImgPaths={benifitImgPaths}
                benifitHeadings={benifitHeadings}
                benifitDescriptions={benifitDescriptions}
            />
            <div className="center">
                <Link to={'/contact-us'} className='shine button partner'>Let's Partner</Link>
            </div>
        </>
    )
}

export default Partner
