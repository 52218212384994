import React from 'react'
import '../css/Technologies.css';
import Heading from './Heading'
import FourBenifits from './FourBenifits';

const Technologies = () => {

    const benifitHeadings = [
        "React.js",
        "Vue.js",
        "Angular",
        // "Bootstrap"
        "Shopify"
    ];

    const descriptions = [
        "Frontend",
        "Frontend",
        "Frontend",
        "E-commerce"
    ];

    const benifitHeadings2 = [
        "Spring Boot",
        "Node.js",
        "Flask",
        "Wordpress"
        // "Laravell"
    ];

    const descriptions2 = [
        "Backend",
        "Backend",
        "Backend",
        "E-commerce"
    ];

    const imgPaths = [
        "../images/technologies/tech-reactjs.png",
        "../images/technologies/tech-vuejs.png",
        "../images/technologies/tech-angularjs.png",
        "../images/technologies/shopify.png",
    ]
    const imgPaths2 = [
        "../images/technologies/tech-spring-boot.png",
        "../images/technologies/tech-nodejs.png",
        "../images/technologies/tech-flask.png",
        "../images/technologies/wordpress.png",
    ]


    return (
        <div className='Technologies'>
            <Heading
                title={"Technologies We Use"}
                description={"Explore the tools and frameworks that power our solutions, ensuring efficiency, reliability, and innovation in every project we deliver."}
            />
            <FourBenifits
                className="first"
                benifitHeadings={benifitHeadings}
                benifitDescriptions = {descriptions}
                benifitImgPaths = {imgPaths}
            />
            <FourBenifits
                className="second"
                benifitHeadings={benifitHeadings2}
                benifitDescriptions = {descriptions2}
                benifitImgPaths = {imgPaths2}
            />
        </div>

    )
}

export default Technologies

