import React from 'react'
import '../../css/footer/Top.css'

const Top = () => {
  return (
    <div>
      
    </div>
  )
}

export default Top
