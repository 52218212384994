import React from 'react';
import emailjs from 'emailjs-com';
import '../css/JoinForm.css';

const Join = () => {
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID_JOIN,
                e.target
            )
            .then((result) => {
                console.log(result.text);
                alert('Form submitted successfully!');
            }, (error) => {
                console.log(error.text);
                alert('Failed to submit the form, please try again.');
            });

        e.target.reset(); // Reset form fields after submission
    };

    return (
        <div className='JoinForm center'>
            <div className="form-content center">
                <div className="join-form">
                    <form onSubmit={sendEmail}>
                        {/* Personal Information */}
                        <h3>Personal Information</h3>
                        <div className="personal-info">
                            <div>
                                <label htmlFor="firstName">First Name</label>
                                <input type="text" id="firstName" name="firstName" placeholder="Enter your first name" required />
                            </div>
                            <div>
                                <label htmlFor="lastName">Last Name</label>
                                <input type="text" id="lastName" name="lastName" placeholder="Enter your last name" required />
                            </div>
                            <div>
                                <label htmlFor="dob">Date of Birth</label>
                                <input type="date" id="dob" name="dob" placeholder="Enter your date of birth" required />
                            </div>
                            <div>
                                <label htmlFor="gender">Gender</label>
                                <select id="gender" name="gender" required>
                                    <option value="male" selected>Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="religion">Religion</label>
                                <select id="religion" name="religion" required>
                                    <option value="islam" selected>Islam</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" name="email" placeholder="Enter your email" required />
                            </div>
                            <div>
                                <label htmlFor="phoneNo">Phone Number</label>
                                <input type="tel" id="phoneNo" name="phoneNo" placeholder="Enter your phone number" required />
                            </div>
                            <div>
                                <label htmlFor="otherNumber">Other Number</label>
                                <input type="tel" id="otherNumber" name="otherNumber" placeholder="Enter another contact number (optional)" />
                            </div>
                            <div>
                                <label htmlFor="nationality">Nationality</label>
                                <select id="nationality" name="nationality" required>
                                    <option value="pakistan" selected>Pakistan</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="cnic">CNIC</label>
                                <input type="text" id="cnic" name="cnic" placeholder="Enter your CNIC (identity no)" required />
                            </div>
                        </div>

                        {/* Address Information */}
                        <h3>Address</h3>
                        <div className="address-info">
                            <div>
                                <label htmlFor="address">Address</label>
                                <input type="text" id="address" name="address" placeholder="Enter your address" required />
                            </div>
                        </div>

                        {/* General Data */}
                        <h3>General Data</h3>
                        <div className="general-data">
                            <div>
                                <label htmlFor="languages">Languages</label>
                                <input type="text" id="languages" name="languages" placeholder="Enter languages you speak" required />
                            </div>
                            <div>
                                <label htmlFor="technologies">Interested Technologies</label>
                                <input type="text" id="technologies" name="technologies" placeholder="Enter technologies you know" required />
                            </div>
                            <div>
                                <label htmlFor="motivation">What motivates you to join?</label>
                                <input type="text" id="motivation" name="motivation" placeholder="Enter your motive" required />
                            </div>
                            <div>
                                <label htmlFor="anythingElse">Any Other Information</label>
                                <input type="text" id="anythingElse" name="anythingElse" placeholder="Information you want to share (optional)" />
                            </div>
                            <div>
                                <label htmlFor="howDidYouKnow">How did you hear about us?</label>
                                <select id="howDidYouKnow" name="howDidYouKnow" required>
                                    <option value="" disabled selected>Please Select</option>
                                    <option value="advertisement">Advertisement</option>
                                    <option value="reference">Reference</option>
                                    <option value="social-media">Social Media</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="submit-button">
                            <button className='shine' type="submit">Submit Form</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Join;
