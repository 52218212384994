import React from 'react'
import '../css/Footer.css'

import Top from './Footer/Top'
import Mid from './Footer/Mid'
import Bottom from './Footer/Bottom'

const Footer = () => {
  return (
    <footer>
        <Top />
        <Mid />
        <Bottom />
    </footer>
  )
}

export default Footer
