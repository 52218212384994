import React from 'react'
import '../css/Image_Details.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';


const Details_Image = ({ detailsFirstHalf, detailsSecondHalf, detailsThirdHalf = "", imgPath, heading }) => {
    useIntersectionObserver('flex');
    useIntersectionObserver('image-and-details');

    return (
        <div className='Image_Details second'>
            <div className='image-and-details reverse'>
                <h1>{heading}</h1>
                <div className="seperator-line"></div>
                <p>{detailsFirstHalf} <br /><br /> {detailsSecondHalf} <br /> <br />{detailsThirdHalf}</p>
            </div>
            {imgPath && (
                <div className='flex hide-on-phone reverse'>
                    <img src={imgPath} alt="right-pic" />
                </div>
            )}
        </div>
    )
}

export default Details_Image
