import React from 'react';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import '../css/Goals.css';

const Goals = () => {
    useIntersectionObserver('fade-in');

    return (
        <div className="Goals center">
            <div className='fade-in'>
                <img src="../images/goals/target.png" alt="Mission" />
                <div>
                    <h3>Our Mission</h3>
                    <p>To empower businesses globally with innovative
                        technology solutions that drive digital
                        transformation and unlock new opportunities.</p>
                </div>
            </div>
            <div className='fade-in'>
                <img src="../images/goals/visions.png" alt="Vision" />
                <div>
                    <h3>Our Vision</h3>
                    <p>To be a global tech leader, shaping the future
                        with groundbreaking innovations that enable
                        success in the digital age.</p>
                </div>
            </div>
            <div className='fade-in'>
                <img src="../images/goals/goal.png" alt="Goal" />
                <div>
                    <h3>Our Goal</h3>
                    <p>To evolve with the tech landscape, delivering
                        cutting-edge solutions and building lasting
                        partnerships that set new industry standards.</p>
                </div>
            </div>
        </div>
    );
}

export default Goals;
