import React from 'react';
import '../css/Contact.css';
import ContactForm from './ContactForm';
import ContactDetails from './ContactDetails';


const scrollToOffset = (offset) => {
    window.scrollTo({
        top: offset,
        behavior: 'smooth'
    });
};

const Contact = () => {
    const handleScroll = (event) => {
        event.preventDefault();
        const offset = window.innerHeight * 0.8;
        scrollToOffset(offset);
    };
    return (
        <><div className='Contact'>
            <div className="home-content center">
                <h1>Contact Us</h1>
                <p>Whether you have a question,
                    need assistance, or want to explore how we can work
                    together, we're here to help. Reach out to us, and
                    we'll respond promptly to ensure your needs are met
                    efficiently. Let’s connect and make great things happen.
                </p>
                <button className='shine button' onClick={handleScroll} aria-label="Send a Message">Send a Message</button>
            </div>
        </div>
            <ContactForm />
            <ContactDetails />
        </>
    );
}

export default Contact;
