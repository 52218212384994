import React from 'react';
import '../css/Heading.css';

const Heading = ({ title, description }) => {
    return (
        <div className='Heading center'>
            <h1 className=''>{title}</h1>
            <div className="seperator-line"></div>
            <p className='width-60'>
                {description}
            </p>
        </div>
    );
}

export default Heading;