import { useEffect } from 'react';

const useIntersectionObserver = (className) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        }
      });
    });

    document.querySelectorAll(`.${className}`).forEach(element => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, [className]);
};

export default useIntersectionObserver;
