import React from 'react'
import '../css/Services.css';

import ServicesCore from './ServicesCore';
import ImageDetails from './ImageDetails';

const Services = () => {
    const heading = "Our Core Services";
    const detailsFirstHalf = `We excel in offering top-tier development
                    services that empower businesses to succeed in the
                    digital world. Our innovative software solutions are
                    designed to address your specific challenges and propel
                    your business forward. Let us help you navigate the
                    ever-evolving digital landscape.`;
    const detailsSecondHalf = `Explore our development services that are designed to 
                    elevate your business. Click on each service to learn 
                    more about how we can support your growth and help you 
                    achieve your goals in the digital era.`;
    const imgPath = "../images/services.png";

    return (
        <><div className='Services'>
            <div className='home-content center'>
                <h1>Our Expertise, Your Success</h1>
                <p>We specialize in delivering high-impact
                    software solutions that drive business growth. Our
                    focus is on providing tailored development services
                    that meet the unique needs of your business, ensuring
                    you stay ahead in the digital age.</p>
            </div>
        </div>
            <ImageDetails
                detailsFirstHalf={detailsFirstHalf}
                detailsSecondHalf={detailsSecondHalf}
                imgPath={imgPath}
                heading={heading}
            />
            <ServicesCore style={{ margin: '5px auto 100px auto' }} />
            </>
    )
}

export default Services
