import React from 'react'
import '../css/ServicesCore.css';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { Link } from 'react-router-dom';

const ServicesCore = ({ style }) => {
    useIntersectionObserver('fade-in');

    return (
        <div className="core-services center" style={style}>
            <Link to='/services/web-development' className="service fade-in">
                <img src="../images/services/www.png" alt="Web Applications" />
                <h3>Web Application Development</h3>
                <p>Empowering your business with custom web applications that enhance performance, drive innovation, and support your growth. Our team designs solutions tailored to your unique requirements.</p>
            </Link>

            <Link to='/services/e-commerce' className="service fade-in">
                <img src="../images/services/laptop.png" alt="E-Commerce Solutions" />
                <h3>E-Commerce Solutions</h3>
                <p>Boost your online sales with our tailored e-commerce solutions. We create user-friendly platforms that provide seamless shopping experiences, helping your business grow in the digital marketplace.</p>
            </Link>

            <Link to='/services/hosting-solutions' className="service fade-in">
                <img src="../images/services/cloud-server.png" alt="Site Hosting Assistance" />
                <h3>Site Hosting Assistance</h3>
                <p>Receive expert support to get your site up and running smoothly. We provide guidance and assistance to ensure your hosting setup is efficient and meets your needs.</p>
            </Link>
        </div>

    )
}

export default ServicesCore
