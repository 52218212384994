import React from 'react'
import '../../css/Process/Process.css';
import { Link } from 'react-router-dom';

import Heading from '../Heading';
import ProcessStep from './ProcessStep';


const Process = () => {
    const steps = [
        { heading: 'Discovery & Planning:', description: 'Identify business goals, target audience, and technical requirements.' },
        { heading: 'Competitor Analysis:', description: 'Analyze industry competitors to identify trends and opportunities.' },
        { heading: 'Requirement Gathering:', description: 'Collect detailed project requirements from stakeholders.' },
        { heading: 'Project Estimation:', description: 'Estimate cost, resources, and timeline based on project scope.' }
    ];

    const steps2 = [
        { heading: 'Information Architecture:', description: 'Organize content and features into a coherent structure.' },
        { heading: 'Wireframing:', description: 'Create low-fidelity layouts to outline basic structure and user flow.' },
        { heading: 'Prototyping:', description: 'Develop interactive prototypes for early user testing and feedback.' },
        { heading: 'Visual Design:', description: 'Craft high-fidelity designs with branding, color schemes, and typography.' }
    ];

    const steps3 = [
        { heading: 'Technical Architecture:', description: 'Define the tech stack, database schema, and system architecture.' },
        { heading: 'Environment Setup:', description: 'Set up development, staging, and production environments.' },
        { heading: 'DevOps & Automation:', description: 'Implement CI/CD pipelines and automated testing frameworks.' }
    ];

    const steps4 = [
        { heading: 'Front-End Development:', description: 'Build responsive user interfaces using the selected framework.' },
        { heading: 'Back-End Development:', description: 'Develop the server, database, and APIs using chosen technologies.' },
        { heading: 'Integration:', description: 'Integrate third-party services and connect front-end with back-end.' }
    ];

    const steps5 = [
        { heading: 'Unit Testing:', description: 'Test individual components for functionality and reliability.' },
        { heading: 'Integration Testing:', description: 'Ensure different modules and services work together seamlessly.' },
        { heading: 'User Acceptance Testing (UAT):', description: 'Validate the product with real users to ensure it meets requirements.' }
    ];

    const steps6 = [
        { heading: 'Performance Optimization:', description: 'Enhance loading times, responsiveness, and overall performance.' },
        { heading: 'Security Testing:', description: 'Identify and mitigate security vulnerabilities.' },
        { heading: 'Accessibility Testing:', description: 'Ensure the app provides a seamless experience for all users.' }
    ];

    const steps7 = [
        { heading: 'Deployment Planning:', description: 'Strategize for a smooth transition from development to production.' },
        { heading: 'Go-Live:', description: 'Deploy the application to the live environment.' },
        { heading: 'Post-Deployment Testing:', description: 'Verify that the live system operates as expected.' }
    ];

    const steps8 = [
        { heading: 'Monitoring & Analytics:', description: 'Track user behavior and system performance post-launch.' },
        { heading: 'Bug Fixes & Updates:', description: 'Continuously improve the app by fixing bugs and rolling out updates.' },
        { heading: 'Scaling:', description: 'Enhance infrastructure to support growing traffic and data loads.' }
    ];

    const steps9 = [
        { heading: 'Ongoing Support:', description: 'Provide technical support and user assistance.' },
        { heading: 'Feature Enhancements:', description: 'Develop new features and improve existing ones based on user feedback.' },
        { heading: 'Documentation:', description: 'Create comprehensive documentation for users and developers.' }
    ];

    return (
        <><div className='Process'>
            <div className="home-content center">
                <h1>Crafting Excellence from Concept to Launch</h1>
                <p>Our comprehensive web development process ensures that every
                    project is meticulously planned, designed, and executed,
                    delivering high-quality web applications that meet your
                    business goals and exceed expectations.</p>
            </div>
        </div>
            <Heading
                title={"Our Web Development Process"}
            />
            <div className="process-step">
                <ProcessStep
                    title='Discovery & Planning'
                    steps={steps}
                />
                <ProcessStep
                    title='Design & Prototyping'
                    steps={steps2}
                />
                <ProcessStep
                    title='Architecture & Setup'
                    steps={steps3}
                />
                <ProcessStep
                    title='Development & Integration'
                    steps={steps4}
                />
                <ProcessStep
                    title='Testing & Validation'
                    steps={steps5}
                />
                <ProcessStep
                    title='Optimization & Security'
                    steps={steps6}
                />
                <ProcessStep
                    title='Deployment & Go-Live'
                    steps={steps7}
                />
                <ProcessStep
                    title='Monitoring & Scaling'
                    steps={steps8}
                />
                <ProcessStep
                    title='Support & Documentation'
                    steps={steps9}
                />
            </div>
            <div className="center">
                <Link to={'/contact-us'} className='shine button partner'>Let's Start</Link>
            </div>       
             </>
    )
}

export default Process;
