import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import '../css/ContactForm.css';

const ContactForm = () => {
    const [countries, setCountries] = useState([]);

    // Fetch countries data when the component mounts
    useEffect(() => {
        async function fetchCountries() {
            try {
                const response = await fetch('https://restcountries.com/v3.1/all');
                const data = await response.json();

                // Sort countries alphabetically by name
                const sortedCountries = data.sort((a, b) => a.name.common.localeCompare(b.name.common));

                setCountries(sortedCountries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        }

        fetchCountries();
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                e.target
            )
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('Failed to send the message, please try again.');
            });

        e.target.reset();
    };

    return (
        <div className="contact-form-content center">
            <div className="contact-form center">
                <form onSubmit={sendEmail}>
                    <div className="general">
                        <div>
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="name" placeholder='Full Name' required />
                        </div>
                        <div>
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" placeholder='Email Address' required />
                        </div>
                        <div>
                            <label htmlFor="company">Company:</label>
                            <input type="text" id="company" name="company" placeholder='Company (if any)' />
                        </div>
                        <div>
                            <label htmlFor="phone">Phone:</label>
                            <input type="tel" id="phone" name="phone" placeholder='Phone No' required />
                        </div>
                        <div>
                            <label htmlFor="country">Country:</label>
                            <select name="country" id="country" required>
                                <option value="" disabled selected>Please select</option>
                                {countries.map((country) => (
                                    <option key={country.cca2} value={country.name.common}>
                                        {country.name.common}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="purpose">Purpose of Contact:</label>
                            <select id="purpose" name="purpose" required>
                                <option value="general-inquiry">General Question</option>
                                <option value="course-information">Course Information</option>
                                <option value="tool-feedback">AI Tool Feedback or Suggestion</option>
                                <option value="technical-support">Technical Support</option>
                                <option value="partnership-opportunities">Partnership Opportunities</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                    </div>
                    <div className="message">
                        <label id='message' htmlFor="message">Message:</label>
                        <textarea id="message" name="message" rows="4" placeholder='Your Message' required />
                    </div>
                    <div className="submit-button contact">
                        <button className='shine' type="submit">Send Message</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ContactForm;
