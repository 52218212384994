import React from 'react'
import '../css/ContactDetails.css';

const ContactDetails = () => {
    return (
        <div className="contacts-details">
            <div className='center'>
                <div className="img">
                    <img src="../images/contact/phone-call.png" alt="phone" />
                </div>
                <div className='address'>
                    <b>Primary No: <a href="tel:+923146036065"> <pre>+92 (314) 6036065</pre></a></b>
                    <b>Secondary:<a href="tel:+923242037312"><pre>+92 (324) 2037312</pre></a></b>
                </div>
            </div>
            <div className='center'>
                <div className="img">
                    <img src="../images/contact/email.png" alt="mail" />
                </div>
                <div className="address">
                    <b>General:  <a href="mailto:info@devsaura.com"><pre>info@devsaura.com</pre></a></b>
                    <b>Projects: <a href="mailto:project@devsaura.com"><pre>project@devsaura.com</pre></a></b>
                </div>
            </div>
        </div>
    )
}

export default ContactDetails
