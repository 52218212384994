import React from 'react'
import '../../css/Web/WebDevelopment.css';
import ImageDetails from '../ImageDetails';
import Heading from '../Heading';
import DetailsImage from '../DetailsImage';
import FourBenifits from '../FourBenifits';
import { Link } from 'react-router-dom';
import Technologies from '../Technologies';


const WebDevelopment = () => {
  const heading = "Web Development"
  const detailsFirstHalf = "In today's digital landscape, a well-crafted website is essential for any business aiming to thrive. At Devsaura, we specialize in building websites that not only attract visitors but also convert them into loyal customers. Our web development services are designed to enhance your online presence, ensuring that your website is a powerful tool for lead generation, sales, and brand engagement."
  const detailsSecondHalf = "We understand the importance of keeping your website current and secure, which is why we offer ongoing maintenance and updates post-launch. This ensures your site remains competitive, ranks well on search engines, and provides a seamless experience for your users. From initial concept to full development and beyond, our team is dedicated to helping your brand achieve lasting success online."

  const detailsFirstHalf2 = "We create websites that seamlessly adapt to any device, ensuring a consistent and engaging user experience. Our approach to responsive design ensures that your site automatically adjusts to different screen sizes and orientations, providing a smooth and intuitive interaction for your visitors. This adaptability not only enhances user satisfaction but also broadens your reach across various platforms, making your online presence stronger and more versatile."

  const benifitHeadings = [
    "Enhanced User Engagement",
    "Scalability & Flexibility",
    "Optimized Performance",
    "Secure & Reliable Operations"
  ];
  const benifitHeadings2 = [
    "Tailored Solutions",
    "Comprehensive Support",
    "Cost-Effective Development",
    "Business Growth Enablement"
  ];



  return (
    <><div className='web-development'>
      <div className='WebDevelopment'>
        <div className="home-content center">
          <h1>Web Application Development</h1>
          <p>We bring your vision to life with tailored
            web solutions. Our expertise lies in creating dynamic,
            user-centric websites that enhance your brand's digital
            presence and drive measurable growth. Whether starting
            from scratch or optimizing an existing site, we build with
            precision to ensure your success online.</p>
          <div className="home-btns">
            <Link to={'/services/web-development/our-process'} className='shine button'> Development Process </Link>
          </div>
        </div>
      </div>
      <ImageDetails
        detailsFirstHalf={detailsFirstHalf}
        detailsSecondHalf={detailsSecondHalf}
        imgPath={"../images/web-design.png"}
        heading={heading} />
      <DetailsImage
        detailsFirstHalf={detailsFirstHalf2}
        imgPath={"../images/responsive-website.png"}
        heading={"Responsive Web Design"} />
      <Heading
        title={"Advantages Of Our Web Development Service"}
        description={"We offer tailored web development services that prioritize intuitive user experiences. Our solutions are designed to adapt to your business needs and ensure long-term success in the digital world."} />
      <FourBenifits
        className="first"
        benifitHeadings={benifitHeadings} />
      <FourBenifits
        className="second"
        benifitHeadings={benifitHeadings2} />
      <div className="heading-cover">
        <Heading
          title={"Ready to Bring Your Web Idea to Life?"}
          description={" Bringing your vision to life is simpler than you might imagine. Need a custom website that perfectly aligns with your business goals? Our scalable web solutions streamline your operations and drive revenue growth. Our team is ready to explore your project ideas. Share your goals with us, and we’ll respond promptly to help you get started."} />
        <div className="center">
          <Link to={'/contact-us'} className='shine button '>Let's Build It</Link>
        </div>
      </div>

    </div>
      <div className="tech-cover">
        <Technologies />
      </div>
      <div className="center">
        <Link to={'/services/web-development/our-process'} className='shine button partner'>Development Process</Link>
      </div>
    </>
  )
}

export default WebDevelopment
